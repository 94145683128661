import 'bootstrap/js/dist/dropdown';

  $("#admin-user-location-change-modal").on("show.bs.modal", function(e) {
    //get data attributes of the clicked element
    const userId = $(e.relatedTarget).data("user");
    const locationId = $(e.relatedTarget).data("id");

    //Write the user id to a hidden input on the change location modal
    $(e.currentTarget)
      .find('input[name="userId"]')
      .val(userId);
    $(e.currentTarget)
      .find('input[name="oldLocationId"]')
      .val(locationId);

    //Get the element that invoked the modal, i.e. the location cell in the table
    const $invoker = $(e.relatedTarget);

    //Save the invoker element to the data attribute of the form
    console.log($invoker);
  });

  $("#admin-user-location-change").submit(function(e) {
    e.preventDefault();

    const userId = $(this)
      .find('input[name="userId"]')
      .val();
    const oldLocationId = $(this)
      .find('input[name="oldLocationId"]')
      .val();
    const location = $(this)
      .find("input.typeahead.location-search.tt-input")
      .val();
    const modal = $(this).parents(".modal");
    const table = modal.siblings("div.table-responsive");
    const cell = table.find(
      `td a[data-user="${userId}"][data-id="${oldLocationId}"]`
    );

    $.ajax({
      dataType: "json",
      type: "post",
      url: "/api/user/location/change/",
      data: {
        user: userId,
        location: location
      },
      success: res => {
        cell.data("id", res);
        cell.text(location);
        modal.modal.hide();
      },
      error: function(msg) {
        console.log(msg);
        modal.modal.hide();
      }
    });
  });

  $("#adminUserLocationModal").on("show.bs.modal", function(event) {
    const cell = $(event.relatedTarget); // Cell that triggered the modal
    const userLocationId = cell.data("id");
    const userId = cell.data("user");
    var modal = $(this);
    // Set the user id hidden input on the location form
    modal.find(":input#userid").val(userId);
  });

  // Modal form on admin user page
  $("#adminUserLocationChange").submit(function(event) {
    const formdata = $(this).serializeArray();
    const locationObj = formdata
      .filter((el, ind, arr) => {
        return el.name === "locationName";
      })
      .shift();
    const locationName = locationObj.value;

    const userObj = formdata
      .filter((el, ind, arr) => {
        return el.name === "userid";
      })
      .shift();
    const userid = userObj.value;

    const ref = $("#adminUserTable tr").filter(function(index) {
      return (
        $(this)
          .find("td:first")
          .html() === userid
      );
    });

    // Write the new location into the table cell
    ref
      .find("td")
      .eq(4)
      .find("a")
      .html(locationName);

    submitAdminUserLocationChangeForm(formdata);

    // Return false to prevent default submit action
    return false;
  });

  function submitAdminUserLocationChangeForm(formdata) {
    console.log("hello");
    $.ajax({
      type: "POST",
      url: "/api/user/location/change",
      cache: false,
      data: formdata,
      success: function(response) {
        console.log("success");
        console.log(response);
        $("#adminUserLocationModal").modal("hide");
      },
      error: function(err) {
        console.log("oops");
        console.log(err);
        $("#adminUserLocationModal").modal("hide");
      }
    });
  }

  $(".admin-user-edit-btn").click(function() {
    window.document.location = $(this).data("href");
  });

  $(".admin-user-delete-btn").click(function(e) {
    const userId = $(this).data("id");
    const $row = $(this).parents("tr");
    const result = confirm("Delete user " + userId + " ?");
    if (result) {
      $.ajax({
        dataType: "json",
        method: "delete",
        url: "/api/user/delete",
        data: {
          user: userId
        },
        success: res => {
          // console.log('success ', res)
          $row.remove();
        },
        error: err => {
          // console.log('my message ' , err);
        }
      });
    }
  });

  $(".admin-listing-edit-btn").on('click',function() {
    const listingId = $(this).data("id");
    window.document.location = "/admin/listing/edit/" + listingId;
  });  

  $(".admin-location-edit-btn").on('click',function() {
    window.document.location =  $(this).data("href");
  });   

  $(".admin-location-keycontact-btn").on('click',function() {
    window.document.location =  $(this).data("href");
  });  

  $(".admin-listing-enable-btn").on('click',function(e) {
    const listingId = $(this).data("id");
    const row = $(this).parents("tr");
    let enabledText = row.find(".enabled-listing");
    const csrfName = $(this).data("csrf-name");
    const csrfValue = $(this).data("csrf-value");
    const data = {"listing" : listingId,  "csrf_name" : csrfName,"csrf_value" : csrfValue};
    console.log('Enable button clicked');
    $.ajax({
      dataType: "json",
      type: "patch",
      url: "/api/listing/enable",
      data: data,
      success: res => {
        enabledText.html(res ? 'Yes' : 'No');
      },
      fail: err => {
        console.log(err);
      }
    });
  });

  $(".admin-listing-delete-btn").on('click', function(e) {
    const listingId = $(this).data("id");
    const $row = $(this).parents("tr");
    const result = confirm("Delete listing " + listingId + " ?");
    const csrfName = $(this).data("csrf-name");
    const csrfValue = $(this).data("csrf-value");
    const data = {"listing" : listingId,  "csrf_name" : csrfName,"csrf_value" : csrfValue}; 

    if (result) {
      $.ajax({
        type: "delete",
        url: "/api/listing/delete",
        data: data,
        success: res => {
          $row.remove();
        },
        error: err => {
          console.log(err);
        }
      });
    }
  });

  $(".admin-listing-keycontact-btn").on('click',function(e) {
    const listingId = $(this).data("id");
    const row = $(this).parents("tr");
    let keyContactText = row.find(".key-contact");
    const csrfName = $(this).data("csrf-name");
    const csrfValue = $(this).data("csrf-value");
    const data = {"listing" : listingId,  "csrf_name" : csrfName,"csrf_value" : csrfValue};
    $.ajax({
      dataType: "json",
      type: "patch",
      url: "/api/listing/key-contact",
      data: data,
      done: res => {
        console.log({res});
        keyContactText.html(res ? 'Yes' : 'No');
      },
      fail: err => {
        console.log(err);
      }
    });
  });
  
  $(".admin-guide-delete-btn").on('click', function(e) {
    const guideId = $(this).data("id");
    const $row = $(this).parents("tr");
    const result = confirm("Delete Guide " + guideId + " ?");
    const csrfName = $(this).data("csrf-name");
    const csrfValue = $(this).data("csrf-value");
    const data = {"guide" : guideId,  "csrf_name" : csrfName,"csrf_value" : csrfValue}; 

    if (result) {
      $.ajax({
        type: "delete",
        url: "/api/guide/delete",
        data: data,
        done: res => {
          $row.remove();
        },
        fail: (res, err) => {
          console.log(err);
        }
      });
    }
  });

  $(".admin-guide-edit-btn").on('click',function() {
    const guideId = $(this).data("id");
    window.document.location = "/admin/guides/edit/" + guideId;
  });



  $(".admin-article-edit-btn").on('click',function() {
    const articleId = $(this).data("id");
    window.document.location = "/admin/article/edit/" + articleId;
  });

  

  $(".admin-article-enable-btn").on('click',function(e) {
    console.log('clicked');
    const articleId = $(this).data("id");
    console.log({'id':articleId});
    const row = $(this).parents("tr");
    let enabledText = row.find(".enabled-article");
    console.log({'enabledText':  enabledText})
    const csrfName = $(this).data("csrf-name");
    const csrfValue = $(this).data("csrf-value");
    const data = {"article" : articleId,  "csrf_name" : csrfName,"csrf_value" : csrfValue};
    $.ajax({
      dataType: "json",
      type: "patch",
      url: "/api/article/enable",
      data: data,
      success: res => {
        enabledText.html(res ? 'Yes' : 'No');
      },
      fail: err => {
        console.log(err);
      }
    });
  });

  $(".admin-article-delete-btn").on('click', function(e) {
    const articleId = $(this).data("id");
    const $row = $(this).parents("tr");
    const result = confirm("Delete article " + articleId + " ?");
    const csrfName = $(this).data("csrf-name");
    const csrfValue = $(this).data("csrf-value");
    const data = {"article" : articleId,  "csrf_name" : csrfName,"csrf_value" : csrfValue}; 

    if (result) {
      $.ajax({
        type: "delete",
        url: "/api/article/delete",
        data: data,
        success: res => {
          $row.remove();
        },
        error: err => {
          console.log(err);
        }
      });
    }
  });





